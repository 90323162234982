import React from 'react'
const Contact = () => {
  return (
    <React.Fragment>
      <h1>
        Contacto
      </h1>
    </React.Fragment>
  )
}
export default Contact;