import React from "react";
const SobreMi = () => {
  let t = window.outerWidth;
  let tt = window.outerHeight;
  return (
    <React.Fragment>
      <h1>
        SobreMi {t} {tt}
      </h1>
    </React.Fragment>
  );
};
export default SobreMi;
